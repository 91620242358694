<template lang="pug">
	div(class="best-practice")
		div(class="row")
			div(class="col s12")
				simplebox(content="Auf dieser Seite findest du ein paar Beispiele wie sich der Dagbort Ticket Shop als White Label Lösung in deine Webseite einbetten lässt. Die Anbindung ist mit wenigen Klicks umsetzbar und erfordert keine tiefergehenden technischen Kenntnisse.")

		div(class="row")
			div(class="col s12")
				separator(text="Dagobert in Action")

		div(class="row box-row")
			div(v-for="(ref, index) in examples" :key="index" class="col l6 m12 s12")
				div(class="contentbox")
					div(class="container")
						div
							center
								img(class="logo" v-bind:src="getImgUrl(ref.logo)")
						div(class="button-wrapper")
							a(class="btn z-depth-0 bp-button" v-bind:href="ref.url" target="_blank") {{ ref.label }}
</template>

<style scoped>
.logo {
	width: auto;
	height: auto;
	max-width: 80%;
}

.button-wrapper {
	margin-top: 1em;
	text-align: center;
}

.bp-button {
	background-color: #e1783e;
	border-radius: 0px;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: bold;
	white-space: nowrap;
}

.contentbox {
	margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
	.contentbox {
		margin-bottom: 25px;
	}
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

import Separator from '@/components/Separator';
import Simplebox from '@/components/Simplebox';

export default {
	mixins: [ UrlMixin ],
	components: {
		'separator': Separator,
		'simplebox': Simplebox
	},
	data: function() {
		return {
			examples: [
				{
					logo: 'external_logos/happiness-logo.png',
					url: 'https://cdn.happiness-festival.de/tickets/',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/bergfestival.png',
					url: 'https://www.berg-festival.com/',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/feel-logo.png',
					url: 'http://feel-festival.de/tickets/',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/reload-logo.png',
					url: 'https://www.reload-festival.de/shop',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/hookup-logo.png',
					url: 'https://hookupfestival.de/tickets/',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/eastercross.png',
					url: 'https://tickets.easter-cross.de/',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/apenair.png',
					url: 'http://apenair.de/?page_id=22',
					label: 'Zum Shop'
				},
				{
					logo: 'external_logos/zimzum.png',
					url: 'https://zimzumfestival.com/tickets/',
					label: 'Zum Shop'
				}
			]
		}
	}
}
</script>